.user-profile-dropdown-toggle__avatar-frame {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: solid 1px #000;
}

.user-profile-dropdown-toggle__avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center center;
}

.user-profile-dropdown-menu {
  border-radius: 5px;
  width: 300px;
  margin: 0;
  padding: 0;
}

.user-profile-dropdown-menu__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 15px;
}

.user-profile-dropdown-menu__avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: solid 1px #000;
  margin-bottom: 15px;
}

.user-profile-dropdown-menu__info {
  text-align: center;
  margin-bottom: 15px;
}

.user-profile-dropdown-menu__info__avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: solid 1px #000;
  margin-bottom: 15px;
}

.user-profile-dropdown-menu__info__username {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}

.user-profile-dropdown-menu__nav-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin: 0 auto;
  width: 86%;
}

.user-profile-dropdown-menu__nav-item {
  border-bottom: solid 1px rgb(221, 219, 219);
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;
  text-indent: 0;

  a {
    color: initial;
    margin-left: -17px;
    cursor: pointer;

    &:hover {
      color: initial;
      opacity: 0.7;
    }
  }
}

.user-profile-dropdown__logout {
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;
  margin-left: 30px;
  cursor: pointer;
  color: initial;

  &:hover {
    color: initial;
    opacity: 0.7;
  }
}

.uploader__modal {
  height: 570px;
  width: 600px;
  border-radius: 5px;
  padding: 30px 20px;
  position: relative;

  &__title {
    font-size: 20px;
    margin-bottom: 15px;
  }

  &__text {
    width: 460px;
    font-size: 13px;
    margin: 0;
    padding: 0;
  }

  &__wrapper {
    width: 460px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &__previewer {
    width: 100%;
    height: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 300px;
      max-height: 320px;
      object-fit: cover;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    width: 460px;
  }

  &__btn {
    height: 35px;
    color: #fff;
    border-radius: 5px;
    width: 70px;
    border: none;
    cursor: pointer;

    &:first-of-type {
      margin-right: 15px;
    }

    &--save {
      background: #a1cf5f;
    }

    &--cancel {
      background: #7f7f7f;
    }
  }
}
