.media-gallery {
  display: flex;
  flex-direction: column;
  width: 70vw;
}

.media-gallery__add-media-button {
  width: 150px;
  height: 40px;
  color: #fff;
  background: #ff6600;
  border-radius: 5px;
  border: none;
}

.media-gallery__media-list {
  padding: 30px 20px;
  display: flex;
  flex-flow: row wrap;
  gap: 25px;
  width: 100%;
  justify-content: space-between;
}

.media-gallery__no-media {
  width: 100%;
  height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__img {
    position: relative;
    left: 20px;
  }

  &__heading {
    margin-top: 25px;
    font-size: 18px;
    font-weight: 500;
    color: #838383;
  }

  &__text {
    font-size: 14px;
    margin: 3px;
  }
}

/********** Media Tabs ************/
.media-tabs {
  background-color: #fff;

  &.nav-tabs {
    border-bottom-width: 2px;
  }

  &.nav {
    .nav-link {
      border-width: 0 0 2px 0;
      &:hover {
        border-color: #a1cf5f !important;
        color: #a1cf5f !important;
      }

      &.active {
        border-color: #a1cf5f !important;
        
        color: #a1cf5f !important;
      }
    }
  }
}