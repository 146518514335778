//
// Aside dark theme
//

// Initialization of global variables, mixins and functions
@import '../../../init';

.aside {
  // Aside Menu
  .aside-menu {
    // Scrollbar
    @include perfect-scrollbar-theme(#9c9c9c);
  }
}

// Build aside menu theme
@include menu-ver-build-theme($aside-menu-config, light);
