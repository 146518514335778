.no-history {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #b2b2b2;
}

.no-history-icon {
  font-size: 100px;
}

.no-history-heading {
  margin-top: 30px;
  font-size: 24px;
  font-weight: 500;
  color: #838383;
}

.no-history-text {
  font-size: 18px;
  margin: 3px;
}

.history-page__add-wysh-button {
  padding: 8px;
  border-radius: 5px;
  color: #fff;
  font-size: 15px;
  background: #ff6600;
  width: 130px;
  border: none;
  padding: 10px;
  margin-bottom: 25px;
}
