.add-media {
  display: flex;
  flex-direction: column;
  position: relative;
}

.add-media__cancel-button {
  width: 150px;
  height: 40px;
  color: #fff;
  background: #ff6600;
  border-radius: 5px;
  border: none;
  align-self: flex-end;
}

.add-media__options {
  width: 100%;
  height: 300px;
  border: solid 1px #e8e8eb;
  margin-bottom: 30px;
  padding: 30px 60px;
  background: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.add-media__options__heading {
  border-bottom: solid 1px #e8e8eb;
  width: 100%;
  height: 70px;

  h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #868686;
  }

  h5 {
    font-size: 15px;
    margin: 0;
    color: #9d9d9d;
  }
}

.add-media__options__list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
}

.video-name-dialogue {
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: space-between;
  padding: 50px;
  position: relative;

  &__close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
  }

  input {
    height: 40px;
    border-radius: 5px;
    border: solid 1px #8f8e8e;
    text-indent: 5px;
  }

  button {
    border: none;
    background: #a1cf5f;
    height: 35px;
    color: #fff;
    border-radius: 5px;
  }
}
