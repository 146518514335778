.user-profile-form-wrapper {
  padding: 30px;
  background: #fff;
  border: solid 1px #dfdfdf;
  border-radius: 5px;
}

.edit-profile-form__button {
  width: 120px;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  border: none;

  &:first-of-type {
    margin-right: 15px;
  }
}

.edit-profile-form__button--edit {
  background: #7d7d7d;
}

.edit-profile-form__button--cancel {
  background: #ff6600;
}
