.aside-menu-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  text-decoration: none;
}

.aside-menu-item--active {
  .aside-menu-item__text {
    color: #fa945b;
  }
}

.aside-menu-item__link {
  text-decoration: none;
  width: 200px;
  display: flex;
  align-items: center;
}

.aside-menu-item__text {
  color: #858585;
  font-size: 15px;
}

.aside-menu-item__icon {
  color: #a7a7a7;
  font-size: 22px;
  margin-right: 12px;
  position: relative;
}

.aside-menu-item button {
  border: none;
  margin: 0;
  padding: 0;

  &:focus,
  &:active {
    outline: none;
  }
}
