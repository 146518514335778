.plans-wrapper {
  border: solid 1px red;
  background: #fff;
  border: solid 1px #dfdfdf;
  padding: 30px;
}

.plans__filters-area {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
}

.plans-filter-button {
  display: block;
  height: 50px;
  width: 130px;
  border-radius: 25px;
  border: 1px solid #87868b;
  color: #6e6e6e;
  background: #fff;
  font-size: 18px;

  &:not(:last-of-type) {
    margin-right: 10px;
  }

  &--active {
    background: #ff6600;
    color: #fff;
    border: none;
  }
}

.plan-card {
  background: #fff;
  border: solid 1px #cecece;
  padding: 50px 20px;
  height: 230px;
  border-radius: 5px;

  &--active {
    background: #ff6600;
    color: #fff;

    .plan-card__body__info {
      color: #fff;
    }

    .plan-fees {
      color: #fff;

      span {
        color: #fff;
      }
    }
  }
}

.plan-card__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.plan-card__title {
  text-align: center;
  font-size: 16px;
}

.plan-card__body__info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #767676;
}

.plan-card__body__cta {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.plan-card__body__cta__btn {
  background: #a1cf5f;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 100px;
  height: 45px;
}

.plan-type-name {
  font-size: 17px;
  font-weight: 500;
}

.plan-type-wyshes {
  font-size: 16px;
}

.plan-fees {
  font-size: 26px;
  color: #a1cf5f;

  span {
    font-size: 18px;
    color: #767676;
  }
}

.active-plan-wrapper {
  display: flex;
  flex-direction: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
