.change-password {
  background: #fff;
  padding: 30px 30px 100px;
  border: solid 1px #dfdfdf;
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.change-password-form {
  flex: 3;
}

.change-password-validation {
  padding-top: 15px;
  flex: 2;
  color: #818181;
}

.change-password-form__button {
  width: 120px;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  border: none;

  &:first-of-type {
    margin-right: 15px;
  }
}

.change-password-form__button--reset {
  background: #7d7d7d;
}

.change-password-form__button--cancel {
  background: #ff6600;
}

.change-password-form__buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

.change-password-validation__title {
  margin-bottom: 30px;
  font-size: 22px;
}

.change-password-validation p {
  font-size: 14px;
}

.password-requirements__text {
  font-size: 14px;
  margin-bottom: 5px;

  svg {
    margin-right: 5px;
    width: 17px;
  }

  img {
    width: 17px;
    height: auto;
    margin-right: 5px;
  }

  &--success {
    color: green;
  }

  &--error {
    color: red;
  }
}
