.dashboard-card {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 15px 17px;
  border: solid 1px #e5e5e5;
  border-radius: 5px;
  height: 140px;
}

.dashboard-card__body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e5e5e5;
  padding-bottom: 15px;
}

.dashboard-card__footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
  color: rgb(126, 123, 123);
}

.dashboard-card__body__info {
  text-align: right;
}

.dashboard-card__body__info__indicator {
  margin-bottom: 7px;
  font-size: 16px;
}

.dashboard-card__body__info__status {
  font-weight: 600;
  font-size: 18px;
}

.dashboard-card-list {
  margin-bottom: 50px;
}
