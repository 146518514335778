.custom-file-uploader {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.custom-file-uploader__input {
  display: none;
}

.custom-file-uploader__button {
  display: block;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: #ff6600;
  border: none;
}

.custom-file-uploader__icon {
  color: #fff;
  font-size: 24px;
}
