.supported-page {
  padding: 1.8rem 1.5rem 1.5rem 1.8rem;
  background-color: #fff;
  border-radius: 5px;


  .submit-btn {
    margin-top: 2rem;
  }

  form {
    max-width: 700px;

    label {
      margin: 1rem 0 1rem 0;
    }
  }
}