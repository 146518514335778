.wysh-scheduling-page__add-wysh {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.wysh-scheduling-page__add-wysh__form-wrapper {
  flex: 5;
  background: #fff;
  padding: 25px;
  border: solid 1px #dfdfdf;
  border-radius: 5px;
}

.add-wysh-form__buttons-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 8px;
}

.file-uploader__wrapper {
  margin: 15px 0;
}

.wysh-scheduling-page__add-wysh__previewer {
  flex: 4;
  background: #fff;
  padding: 25px;
  border: solid 1px #dfdfdf;
  height: 700px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.add-wysh-previewer__body {
  height: 500px;
  width: 100%;
}

.add-wysh-previewer__content {
  height: 70%;

  &__text {
    max-height: 150px;
    max-width: 500px;
    overflow-x: auto;
    overflow-y: auto;
    text-align: left;
    padding: 0 15px;
  }

  &__media {
    width: 100%;
    height: 100%;
    margin-bottom: 30px;

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.add-wysh-previewer__empty {
  color: #b2b2b2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__icon {
    img {
      width: 100px;
      position: relative;
      left: 17px;
    }
  }

  &__heading {
    margin-top: 25px;
    font-size: 18px;
    font-weight: 500;
    color: #838383;
  }

  &__text {
    font-size: 14px;
    margin: 3px;
  }
}

.add-wysh-previewer__footer {
  margin-top: 20px;
  height: 50px;

  img {
    width: 40px;
  }
}

.file-uploader__close-button {
  background: transparent;
  border: none;
  position: relative;
  top: 2px;
  font-size: 18px;
  margin-left: 8px;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}
