.beneficiaries-list {
  background: #fff;
  padding: 20px;
  border: 1px solid #dfdfdf;
}

.beneficiaries-list__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}

.beneficiaries-list__filter-button {
  background: #ff6600;
  color: #fff;
  width: 150px;
  height: 40px;
  border-radius: 5px;
  border: none;
}
