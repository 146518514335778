.recommendation-form-container {
  width: 100%;
  height: 100%;
  background: #fff;
  border: solid 1px #e0e0e0;
  border-radius: 5px;
  padding: 30px;
}

.recommendation-form-container__heading {
  width: 100%;
  height: 50px;
  font-size: 18px;
  background: #fff;
  border-bottom: solid 1px #e0e0e0;
  margin-bottom: 30px;
  font-weight: 500;
}

.recommendation-form__half {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 25px;
  width: 100%;
}

.recommendation-form__input-group {
  margin: 15px 0;
  width: 100%;

  textarea,
  input {
    text-indent: 5px;
    border: solid 1px #e0e0e0;
    border-radius: 5px;
    width: 100%;
  }

  input {
    height: 35px;
  }
}

.recommendation-form {
  height: 100%;
  max-height: 300px;
}

.recommendation-form__cta {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100px;
}

.recommendation-form__submit-button {
  width: 100px;
  height: 45px;
  background: #a1cf5f;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-top: auto;
}
