.media-card {
  background: #fff;
  border: solid 1px #d6d4d4;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 530px;
  height: 220px;
  padding: 40px;
}

.media-card__actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;

  button {
    width: 50px;
    height: 25px;
    border: none;
    border-radius: 5px;
    color: #fff;
  }

  &__view-button {
    background: #a1cf5f;
  }

  &__edit-button {
    background: #ff6600;
  }

  &__delete-button {
    background: #d50000;
  }
}

.media-card__info {
  min-width: 400px;
  display: flex;
  flex: row;
  align-items: center;
  justify-content: space-between;

  &__data {
    width: 230px;
    height: 140px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;

    &__title {
      color: #767676;
      font-weight: 500;
      font-size: 22px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 10px;
    }

    &__text {
      margin: 5px 0;
      padding: 0;
      font-size: 13px;
      line-height: 1;
    }
  }

  &__media {
    width: 140px;
    height: 140px;
    padding: 3px;
    border: solid 1px #c0c0be;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__media2 {
    width: 140px;
    height: 130px;
    // padding: 3px;
    // border: solid 1px #c0c0be;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.media-card__previewer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 100000;

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 10px solid #fff;
    border-radius: 5px;
  }
}

.media-card__custom-modal {
  width: 800px;
  height: 600px;
}

.media-card__close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: bold;
  color: #c0c0be;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  z-index: 100100;

  &:hover {
    opacity: 0.5;
  }
}
