.user-plan-card {
  height: 100%;
  background: #fff;
  border: solid 1px #e0e0e0;
  border-radius: 5px;
  padding: 30px;
  display: flex;
  flex-direction: column;

  &--small {
    .user-plan-card__info__package {
      &__name {
        img {
          width: 27px;
          position: relative;
          bottom: 2px;
        }

        h1 {
          font-size: 30px;
        }
      }

      &__expiry {
        font-size: 12px;
      }
    }

    .user-plan-card__info__package-detail {
      font-size: 16px;
    }
  }
}

.user-plan-card__upgrade-button {
  width: 100px;
  height: 45px;
  background: #a1cf5f;
  color: #fff;
  border: none;
  border-radius: 5px;
}

.user-plan-card__header {
  margin-bottom: 20px;

  &__title {
    font-size: 17px;
    font-weight: 500;
  }

  &__subtitle {
    padding-top: 10px;
    font-size: 14px;
    color: #767676;
    font-weight: 400;
  }
}

.user-plan-card__info__package {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  height: fit-content;
  margin-bottom: 40px;

  &__name {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: #ff6600;

    img {
      width: 37px;
      position: relative;
      bottom: 2px;
    }

    h1 {
      font-size: 40px;
    }
  }

  &__expiry {
    color: #8e8e8e;
    font-size: 14px;
    margin: 0;

    p {
      margin: 3px 0;
    }
  }
}

.user-plan-card__info__package-detail {
  font-size: 20px;
  border-bottom: solid 1px #e0e0e0;
  margin-bottom: 15px;
  height: 40px;
}

.user-plan-card__cta {
  margin-top: auto;
  justify-self: flex-end;
  align-self: flex-end;
}

.user-plan-card__select-list {
  padding-top: 30px;

  &__item-wrapper {
    border-bottom: solid 1px #e8e8e8;
    height: 40px;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 15px;
  }

  &__item {
    display: flex;
    flex-direction: row;
    width: 100%;

    input {
      position: relative;
      top: 8px;
    }
  }

  &__item__text {
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    font-weight: 500;

    &__name {
      flex: 2;
      color: #8c8c8c;
      font-size: 20px;
      font-weight: 600;
    }

    &__price {
      flex: 1;
      text-align: left;
    }

    &__cards {
      flex: 2;
      text-align: right;
    }
  }
}

.dashboard__chart-list {
  margin-bottom: 30px;
}

.dashboard__app-list {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.card-ios{
  width: 100%;

}

.card-ios-fixed .modal-dialog{
  min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
}



// Mobile mode
@media (max-width: 768px) {
  .user-plan-card__info__package {
    display: block;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    height: fit-content;
    margin-bottom: 40px;
  
    &__name {
      display: block;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      color: #ff6600;
  
      img {
        width: 37px;
        position: relative;
        bottom: 2px;
      }
  
      h1 {
        font-size: 40px;
      }
    }
  
    &__expiry {
      color: #8e8e8e;
      font-size: 14px;
      margin: 0;
  
      p {
        margin: 3px 0;
      }
    }
  }
}